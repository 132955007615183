@import '../../../colors.scss';

.tab-container {
    max-width: 100%;
    margin: 32px auto;
}

.tabs {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    background-color: $secondary;
    border-radius: 0px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.tab {
    padding: 10px 20px;
    border: none;
    color: $white;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;
    font-size: 16px;
}

.tab.active {
    background-color: $fourth;
    border-radius: 0px;
    color: $secondary;
    font-weight: bold;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.tab-content {
    padding: 16px;
    background-color: $fourth;
    border-radius: 0px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

/* Additional styling to simulate content cards, based on your image */
.tab-content div {
    padding: 10px;
    color: #333;
}

.tab-panel {
    display: none;
}

.tab-panel.active {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.hidden {
    display: none;
}