// @import '../../../colors.scss';

// .ScenariosComponentUpdated {
//     margin: 32px 0;

//     .scenario-card {
//         transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

//         &:hover,
//         &.active {
//             transform: translateY(-10px);
//             box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.4);
//         }

//         &.active {
//             border: 2px solid $primary;
//         }
//     }

//     .selected-scenario-details {
//         margin-top: 20px;
//         padding: 20px;
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;
//         box-shadow: none !important;

//         .selected-scenario-image {
//             margin-bottom: 32px;
//             padding: 16px;
//             border-radius: 16px;
//             width: 50%;
//             max-width: 200px;
//             height: auto;
//             background-color: $primary;
//         }
//     }
// }

@import '../../../colors.scss';

.ScenariosComponentUpdated {
    .selected-scenario-display {
        text-align: center;
        background-color: $fourth; // Choose a light grey background for contrast
        padding: 16px;
        box-shadow: 0 4px 8px rgba($black, 0.1); // Subtle shadow for depth

        .selected-scenario-image {
            margin: 0 auto;
            padding: 16px;
            border-radius: 16px;
            background-color: $secondary;
        }
    }

    .scenario-card {
        height: 250px;
        border-radius: 0px; // Soft rounded corners
        box-shadow: 0 2px 4px rgba($black, 0.05); // Light shadow for flat design

        &:hover {
            height: 250px;
            box-shadow: 0 6px 12px rgba($black, 0.1); // Slightly more pronounced shadow on hover
            transform: translateY(-5px); // Gentle lift effect
        }

        &.active {
            background-color: $secondary; // Highlight the active card with primary color
            color: $white; // Use white text for better readability on active card

            .MuiCardContent-root {
                .MuiTypography-root {
                    color: $white; // Ensuring all text is white for active cards
                }
            }
        }
    }
}