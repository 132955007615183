@import '../../../colors.scss';

.AboutUsFourthComponentWrapper {
    margin-top: 48px;
    height: 50vh;
    background-image: url(../../../assets/images/global.jpg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: auto auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .AboutUsFourthComponentContainer {
        background-color: rgba($secondary, 0.86);
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .AboutUsFourthComponentTitle {
            color: $white;
            font-weight: bold;
        }

        .AboutUsFourthComponentSubTitle {
            margin-top: 16px;
            color: $white;
        }

        .AboutUsFourthComponentLink {
            margin-top: 32px;

            .AboutUsFourthComponentButton {
                color: $white;
                border: 2px solid $white;
                border-radius: 0px;
                padding: 12px 32px;
            }
        }
    }

}