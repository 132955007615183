@import '../../../colors.scss';

.HomePageThirdComponentWrapper {
    background-color: rgba($primary, 0.5);
    padding: 16px 16px 100px 16px;

    .HomePageThirdComponentTitlesContainer {
        color: $white;
    }

}

.HomePageThirdComponentDataContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: -48px;

    .HomePageThirdComponentDataBox {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 16px;
        background-color: $white;
        height: 170px;
        width: 230px;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;

        .HomePageThirdComponentDataImage {
            background-color: $third;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            width: 100%;
        }

    }
}