@import '../../../colors.scss';

.HomePageSecondComponentWrapper {
    margin-bottom: 48px;
    height: 340px;
    background-color: $fourth;

    @media screen and (max-width:899.5px) {
        height: 250px;
        margin-bottom: 200px;
    }


    .HomePageSecondComponentContainer {
        height: 100%;

        .HomePageSecondComponentTitleContainer {
            height: 100%;
            background-color: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .HomePageSecondComponentTitle {
                color: $white;
                font-weight: bold;
                margin-bottom: 24px;
            }

            .HomePageSecondComponentSubTitle {
                color: $white;
            }
        }

        .HomePageSecondComponentContentContainer {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;

            .HomePageSecondComponentBoxContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                width: 250px;

                .HomePageSecondComponentBoxDescription {
                    color: $third !important;
                    font-weight: bold;
                    margin-bottom: 32px;
                }

                .HomePageSecondComponentBoxTitle {
                    color: $primary;
                    font-weight: bold
                }
            }
        }
    }

    // .HomePageSecondComponentContainer {
    //     height: 340px !important;
    //     text-align: center;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-around;
    //     flex-direction: row;

    //     .HomePageSecondComponentTitlesContainer {
    //         text-align: left;

    //         .HomePageSecondComponentTitle {
    //             color: $white !important;
    //             font-weight: bold !important;
    //         }

    //         .HomePageSecondComponentSubTitle {
    //             color: rgba($white, 0.8) !important;
    //             margin-top: 100px !important;
    //         }
    //     }

    //     .HomePageSecondComponentContentContainer {
    //         // margin-top: 48px;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         flex-direction: column;
    //         flex-wrap: wrap;
    //         gap: 16px;

    //         .HomePageSecondComponentBoxContainer {
    //             .HomePageSecondComponentBoxTitle {
    //                 color: $white;
    //                 font-weight: bold;
    //             }

    //             .HomePageSecondComponentBoxDescription {
    //                 color: rgba($white, 0.8);
    //             }
    //         }

    //         .divider {
    //             height: 1px;
    //             width: 200px;
    //             background-color: $secondary;
    //             margin: 0px 0px;
    //         }
    //     }
    // }

}