@import '../../../colors.scss';

.systemFirstComponent {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $fourth;
}

.systemFirstComponentCard {
    backdrop-filter: blur(10px) !important;
    background: linear-gradient(to bottom right, $third, $primary) !important;
    border-radius: 0px !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px,
        rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px,
        rgba(0, 0, 0, 0.09) 0px -3px 5px !important;
}

.systemFirstComponentMainContent,
.systemFirstComponentSidebar {
    transition: all 0.3s ease;
}

.systemFirstComponentContentTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: $primary;
    font-weight: bold !important;
    margin-bottom: 16px;
}

.systemFirstComponentContent {
    color: $primary;
    /* Dark grey text for readability */
}

.systemFirstComponentSideContent {
    margin-bottom: 24px;
    padding: 16px;
    background: rgba($white, 0.4);
    /* Light background for sidebar content */
    border-radius: 8px;
    /* Rounded corners for sidebar content */
}

.systemFirstComponent .systemFirstComponentSidebar:hover .systemFirstComponentSideContent {
    background: rgba($primary, 0.1);
    /* Hover effect for sidebar content */
    cursor: pointer;
}