@import '../../../colors.scss';

.HomePageFirstComponentWrapper {
    background-color: rgba($primary, 0.5);
    padding: 16px 16px 100px 16px;

    .HomePageFirstComponentTitlesContainer {
        color: $white;
    }
}

.HomePageFirstComponentDataContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: -48px;

    .HomePageFirstComponentDataBox {
        display: flex;
        align-items: center;
        gap: 16px;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
        background-color: $white;
        height: 150px;

        .HomePageFirstComponentDataImage {
            background-color: $third;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            height: 100%;
        }

        .HomePageFirstComponentDataText {
            padding: 16px;
        }
    }
}