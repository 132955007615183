@import '../../../colors.scss';

.AboutUsSecondComponentWrapper {
    margin-top: 16px;
    position: relative;
    overflow: hidden;
}

.AboutUsSecondComponentTitlesContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .AboutUsSecondComponentTitle {
        font-weight: bold;
        color: $white;
    }

    .AboutUsSecondComponentSubTitle {
        margin-top: 16px;
        text-align: left;
        color: rgba($white, 0.8);
    }
}

.AboutUsSecondComponentCardsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
    margin: 32px 0px;
}

.AboutUsSecondComponentWrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, $third, $primary);
    background-size: cover;
    transform: rotate(8deg);
    z-index: -1;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 40px 0;
}

.container .card {
    position: relative;
    min-width: 280px;
    height: 280px;
    // box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    //     inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    //     5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
    border-radius: 0px;
    margin: 8px;
    transition: 0.5s;
    background-color: transparent;
    border: none;
}

.container .card .box {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: rgba($secondary , 0.8);
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: 0.5s;
}

.container .card .box:hover {
    transform: translateY(-50px);
}

.container .card .box:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.04);
}

.container .card .box .content {
    padding: 20px;
    text-align: center;
}

.container .card .box .content h2 {
    position: absolute;
    top: -10px;
    right: 30px;
    font-size: 8rem;
    color: rgba(255, 255, 255, 0.2);
}

.container .card .box .content h3 {
    font-size: 1.8rem;
    color: #fff;
    z-index: 1;
    transition: 0.5s;
    margin-bottom: 15px;
}

.container .card .box .content p {
    font-size: 1rem;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.9);
    z-index: 1;
    transition: 0.5s;
}

.container .card .box .content a {
    position: relative;
    display: inline-block;
    padding: 8px 20px;
    background: black;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    margin-top: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
}

.container .card .box .content a:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
    background: #fff;
    color: #000;
}