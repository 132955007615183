@import '../../colors.scss';

.HomePageFirstThirdWrapper {
    background-image: url(../../assets/images/global.jpg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: auto auto;

    .HomePageFirstThirdContainer {
        background-color: rgba($secondary, 0.86);
        height: auto;
    }
}