@import '../../colors.scss';

.signinWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: $primary;

    form {
        width: 340px;
        padding: 40px;
        background: $primary;
        box-shadow: 20px 20px 60px $secondary, -20px -20px 60px $secondary;
        border-radius: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            margin-bottom: 10px;
            color: $white;
        }

        label {
            align-self: flex-start;
            margin-top: 20px;
            color: $white;
        }

        input {
            width: 100%;
            padding: 12px;
            margin-top: 8px;
            background: $secondary;
            border: 1px solid $primary;
            outline: none !important;
            color: $white;
            border-radius: 0px;
            // box-shadow: inset 8px 8px 8px #cbcbcb, inset -8px -8px 8px #ffffff;
        }

        button {
            margin-top: 30px;
            width: 100%;
            padding: 10px;
            background: $primary;
            color: #ffffff;
            font-weight: bold;
            border-radius: 10px;
            cursor: pointer;
            // box-shadow: 2px 2px 5px $third;
            border: 1px solid $white;
            transition: background-color 0.3s;

            &:hover {
                background: $secondary;
            }
        }

        .signinback {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #e0e0e0;
            box-shadow: 5px 5px 10px $secondary, -5px -5px 10px $secondary;
            cursor: pointer;

            &:hover {
                background-color: #d1d1d1;
            }
        }
    }
}